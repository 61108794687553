import React, { Component } from 'react'
import { Route, Routes, Link, useLocation } from 'react-router-dom'
import Translation from '../user/Translation'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP"

import AnimalGroup from '../charts/AnimalGroup.js'
import AnimalGroupRecipe from '../charts/AnimalGroupRecipe.js'
import Feeds from '../charts/Feeds.js'
import Recipes from '../charts/Recipes.js'
import Contact from './Contact.js'
import Tables from '../charts/Tables.js'
import Timechart from '../charts/Timechart.js'
import Energy from '../charts/Energy.js'
import Efficiency from '../charts/Efficiency.js'

function t(field) { return Translation.get(field) }

function SideBar() {
  const location = useLocation()
  const path = location.pathname.split("/")
  let second = path[2]
  let id= null
  if (path[2] && path[2].indexOf(":") === 0) {
    id = path[2].substring(1, path[2].length)
    window.sessionStorage.setItem("robot_id", id)
  }
  if (path[3] && path[3].indexOf(":") === 0) {
    if (id === null) {
      id = path[3].substring(1, path[3].length)
      window.sessionStorage.setItem("robot_id", id)
    }
    else window.sessionStorage.setItem("robot_name", path[3].substring(1, path[3].length))
  }
  function over(e) {
    switch(e.target.id) {
      case "nav_animalgroup": e.currentTarget.src =  require("../img/navi/mouseover/chartgroup.png"); break
      case "nav_stat_recipe": e.currentTarget.src =  require("../img/navi/mouseover/stat_recipe.png"); break
      case "nav_feeds":       e.currentTarget.src =  require("../img/navi/mouseover/chartfeed.png"); break
      case "nav_tables":      e.currentTarget.src =  require("../img/navi/mouseover/tables.png"); break
      case "nav_energy":      e.currentTarget.src =  require("../img/navi/mouseover/energy.png"); break
      case "nav_timechart":   e.currentTarget.src =  require("../img/navi/mouseover/timechart.png"); break
      case "nav_group_recipe": e.currentTarget.src =  require("../img/navi/mouseover/compare.png"); break
      case "nav_efficiency":  e.currentTarget.src =  require("../img/navi/mouseover/efficiency.png"); break
      case "nav_contact":     e.currentTarget.src =  require("../img/navi/mouseover/contact.png"); break
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      case "nav_animalgroup": if (second === "animalgroup") e.currentTarget.src = require("../img/navi/active/chartgroup.png"); else e.currentTarget.src =  require("../img/navi/chartgroup.png"); break
      case "nav_stat_recipe": if (second === "stat_recipe") e.currentTarget.src = require("../img/navi/active/stat_recipe.png"); else e.currentTarget.src =  require("../img/navi/stat_recipe.png"); break
      case "nav_feeds":       if (second === "feeds") e.currentTarget.src = require("../img/navi/active/chartfeed.png"); else e.currentTarget.src =  require("../img/navi/chartfeed.png"); break
      case "nav_tables":      if (second === "tables") e.currentTarget.src = require("../img/navi/active/tables.png"); else e.currentTarget.src =  require("../img/navi/tables.png"); break
      case "nav_energy":      if (second === "energy") e.currentTarget.src = require("../img/navi/active/energy.png"); else e.currentTarget.src =  require("../img/navi/energy.png"); break
      case "nav_timechart":   if (second === "timechart") e.currentTarget.src = require("../img/navi/active/timechart.png"); else e.currentTarget.src =  require("../img/navi/timechart.png"); break
      case "nav_group_recipe": if (second === "group_recipe") e.currentTarget.src = require("../img/navi/active/compare.png"); else e.currentTarget.src =  require("../img/navi/compare.png"); break
      case "nav_efficiency":  if (second === "efficiency") e.currentTarget.src = require("../img/navi/active/efficiency.png"); else e.currentTarget.src =  require("../img/navi/efficiency.png"); break
      case "nav_contact":     if (second === "contact") e.currentTarget.src = require("../img/navi/active/contact.png"); else e.currentTarget.src =  require("../img/navi/contact.png"); break
      default:
    }
  }
  return (
    <div className="nav_left_main">
      <Link to="feeds"><img id="nav_feeds" src={second === "feeds" ? require("../img/navi/active/chartfeed.png") : require("../img/navi/chartfeed.png")} alt={t('nav_feeds')} title={t('nav_feeds')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="animalgroup"><img id="nav_animalgroup" src={second === "animalgroup" ? require("../img/navi/active/chartgroup.png") : require("../img/navi/chartgroup.png")} alt={t('nav_animalgroup')} title={t('nav_animalgroup')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="group_recipe"><img id="nav_group_recipe" src={second === "group_recipe" ? require("../img/navi/active/compare.png") : require("../img/navi/compare.png")} alt={t('nav_group_recipe')} title={t('nav_group_recipe')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="stat_recipe"><img id="nav_stat_recipe" src={second === "stat_recipe" ? require("../img/navi/active/stat_recipe.png") : require("../img/navi/stat_recipe.png")} alt={t('nav_stat_recipe')} title={t('nav_stat_recipe')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="timechart"><img id="nav_timechart" src={second === "timechart" ? require("../img/navi/active/timechart.png") : require("../img/navi/timechart.png")} alt={t('nav_timechart')} title={t('nav_timechart')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="energy"><img id="nav_energy" src={second === "energy" ? require("../img/navi/active/energy.png") : require("../img/navi/energy.png")} alt={t('nav_energy')} title={t('nav_energy')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="efficiency"><img id="nav_efficiency" src={second === "efficiency" ? require("../img/navi/active/efficiency.png") : require("../img/navi/efficiency.png")} alt={t('nav_efficiency')} title={t('nav_efficiency')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="tables"><img id="nav_tables" src={second === "tables" ? require("../img/navi/active/tables.png") : require("../img/navi/tables.png")} alt={t('nav_tables')} title={t('nav_tables')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="contact" className="bottom"><img className="bottom" id="nav_contact" src={second === "contact" ? require("../img/navi/active/contact.png") : require("../img/navi/contact.png")} alt={t('nav_contact')} title={t('nav_contact')} onMouseOver={over} onMouseOut={out}/></Link>
    </div>
  )
}

class RobotStats extends Component {
  constructor()
  {
    super()
    this.state={
      geturl: "/robots/get_robots.php?id=",
      data: [],
      robottypes: [],
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + window.sessionStorage.getItem("robot_id")).then((result) => {
      if (result.data) result.data.password=null
      this.setState({
        data: result.data,
        robottypes: result.robottypes,
      })
      if (result.data) window.sessionStorage.setItem("robot_name", result.data[0].name)
    })
  }

  render() {
    return (
      <div>
        <div className="nav2">
          <nav id="navigation_2">< SideBar /></nav>
        </div>
        <div className="content">
        <div className="body">
        <div className="tablebody">
          <Routes>
            <Route path="" element={<AnimalGroup />} />
            <Route path="animalgroup/*" element={<AnimalGroup />} />
            <Route path="group_recipe/*" element={<AnimalGroupRecipe />} />
            <Route path="feeds/*" element={<Feeds />} />
            <Route path="stat_recipe/*" element={<Recipes />} />
            <Route path="timechart" element={<Timechart />} />
            <Route path="energy" element={<Energy />} />
            <Route path="efficiency" element={<Efficiency />} />
            <Route path="tables" element={<Tables />} />
            <Route path="contact" element={<Contact />}/>
          </Routes>
        </div>
      </div>
        </div>
      </div>
    )
  }
}

export default RobotStats