import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import Draggable from "react-draggable";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP from "./api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import getStatusVars, {getStatusColors, setTableFontSize} from './api/imports.js';
import Info from "./api/Info";
//import imgWarnOnline from './img/careful.png'

import './styles/react-table.css';
//import imgOk from './img/ok.png'
//import imgAttention from './img/attention.png'
//import imgArrow from './img/arrow_right.png'
//import imgTrue from './img/true.gif'
//import imgFalse from './img/false2.png'

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      geturl: "/feed/get_paths.php",
      saveurl: "/feed/set_paths.php?",
      setTableUrl: "/users/change_tablesize.php",
      data: [], selected: null, empty: [{id:-1, customer_id: -1, sps_nr: 0, name: ""}],// Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      edit: false,
      fontSize: setTableFontSize(),
    }
    this.handleClose = this.handleClose.bind(this)
  }
  reload() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({
        data: result.data,
        selected: null,
      })
    })
    this.props.reload()
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    return {
      data: props.paths,
    }
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+20*em)+'px'
    e1.style.top =(window.scrollY+12*em)+'px';
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    return [
      { accessor: 'id', show: false, },
      this.addTextCell('route_nr', 'sps_nr', 1, 3*this.state.fontSize, true, "", null, null, null, false),
      this.addTextCell('box_path', 'name', 0, 20, true, "", null, null, null, true),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 8.2*em,
        filterable: false,
        resizable: false,
        //Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false, true, true) },
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false,) },
      }
    ]
  }

  render() {
    //console.log('paths')
    if (this.state.id !== 0) {
      const columns = this.createColumns()
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        e1.style.left = (window.scrollX+20*em)+'px'
        e1.style.top =(window.scrollY+12*em)+'px';
      }
      return (
        <Draggable handle=".handle">
        <div className="edittable" id="edit_window">
          <div className="header">
            <div className="header_inline handle">{t('box_path')}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <div style={{"font-size": this.state.fontSize+"em"}}>
          <div className="table_60">
            <ReactTable
              data={this.state.data}
              columns={columns}
              minRows = {0}
              previousText= {'<'}
              nextText= {'>'}
              useFlexLayout = {true}
              showPaginationBottom={false}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                    onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected) { this.setState({ selected: rowInfo.index, }) } },
                    style: {
                      background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                      color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                    }
                }
              }}
            />
          </div></div>
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}

class Boxes extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_box.php",
      saveurl: "/feed/set_box.php",
      updateurl: "/feed/send_box.php",
      setTableUrl: "/users/change_tablesize.php",
      showHelpId: null,
      data: [], empty: null, selected: null, // Table data
      customers: null, side: ['', t('side_left'), t('side_right')], path: [], pathList: [], // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(), fontSize: setTableFontSize(),
      robotOnline: 0,  boxesToUse: 150,
      showHideEdit: false,
      tablePageSize: 0, fieldWidths: null,
    }
    this.reload = this.reload.bind(this)
    this.closeEdit = this.closeEdit.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  closeEdit() {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount(this.state.historic, null, 0, this.state.archive)
  }

  componentDidMount(historic = this.state.historic) {
    let addLink = ""
    if (historic) addLink = "?historic=1"
    GetJsonFormPHP(this.state.geturl + addLink).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      this.setState({
        data: result.data,
        customers: result.customers,
        path: result.paths,
        pathList: result.pathlist,
        robotOnline: result.robot_online,
        fieldWidths: result.fieldwidth,
        tablePageSize: pageSize,
        boxesToUse: result.boxes_left,
      })
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const su = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkSupport()
    const userRight = UserProfile.checkRight("custuser")
    const feeder = UserProfile.getFeeder()
    const w = this.state.fieldWidths
    const fontSize = this.state.fontSize
    //console.log(w)
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'customer_id', show: false,},
      { accessor: 'org_id', show: false,},
      { accessor: 'used_in', show: false,},
      { accessor: 'sps_nr',
        Header: t('box_sps_nr'),
        getProps: (state, rowInfo) => ({ style: { textAlign: "right" } }),
        Cell: ({value: initialValue, column: { id }, row,}) => { if (su) return this.textCell(initialValue, id, row, 1); else return parseInt(initialValue) < 0 ? null : initialValue },
        width: (w && w['sps_nr'] ? w['sps_nr'] : 3.8)*em,
      },
      this.addTextCell('name', 'name',0, (w && w['name'] ? w['name'] : 0)*fontSize),
      this.addTextCell('box_begin', 'begin', 2, (w && w['begin'] ? w['begin'] : 6)*fontSize, true, 'm', null, null, null, true, false, 1),
      this.addTextCell('box_end', 'end', 2, (w && w['end'] ? w['end'] : 6)*fontSize, true, 'm'),
      this.addSelectCell("box_side", "side", this.state.side, (w && w['side'] ? w['side'] : 5.5)*fontSize, true, 'center', null, false),
      this.addTextCell('box_offset', 'offset', 2, (w && w['offset'] ? w['offset'] : 5)*fontSize, feeder === "ARANOM" ? true : false, "m", null, null, null),
      this.addSelectCell("box_path", "path", this.state.path, (w && w['path'] ? w['path'] : 6.5)*fontSize, true , null, null, false, feeder === "ARANOM" ? true : false),
      this.addCheckCell("box", true, null, null, null),
      this.addSelectCell("status", "status", this.state.status, (w && w['status'] ? w['status'] : 5.5)*fontSize, supportRight, 'center', this.getColor('cellAdmin'), false, su),
      this.addDateCell('create_time', 'last_update', true, (w && w['last_update'] ? w['last_update'] : 8.5)*fontSize, supportRight, null, this.getColor('readOnly'), null, false),
      this.addTextCell('last_user_name', 'user', 0, (w && w['user'] ? w['user'] : 6)*fontSize, supportRight, "", null, this.getColor('cellAdmin'), this.getColor('textAdmin'), false),
      { accessor: 'edit',
        Header: t('table_edit'),
        //width: (supportRight ? 8.2 : 5.5)*fontSize*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          let del = false, edit = false, update = false, archiv = false
          if (row.status < 2 || supportRight) del = true
          if (row.id > 0 && row.status <= 4) edit = true
          if (row.used) edit = false
          if ((row.status === 1 || row.status === 2) &&  this.state.selected !== row.id && row.side > 0 && (row.path > 0 || feeder !== "ARANOM")) update = true
          if (row.status === 4 && row.used === 0) archiv = true
          //console.log(row.sps_nr, this.state.boxesToUse, update)
          if (row.sps_nr <= 0 && this.state.boxesToUse <= 0) update = false
          if (supportRight) edit = true
          return (
            <div>
              {this.edit(row, false, del && userRight, edit && userRight, update && userRight, archiv && userRight)}
              {//row.status < 4 && this.state.robotOnline === 0 ? <img src={imgWarnOnline} alt="!" title={t("robot_offline")} /> : null
              }
            </div>
          )
        },
      }
    ]
    return columns
  }

  // {userRight ? <button onClick={() => {this.insertRow()}}>{t("addline")}</button> : null }
  render() {
    const columns = this.createColumns()
    const supportRight = UserProfile.checkSupport()
    const userRight = UserProfile.checkRight("custuser")
    //const feeder = UserProfile.getFeeder()
    //const traderRight = UserProfile.checkRight("trader")
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=box&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    let buttonHistoric = t('show_history')
    if (this.state.historic) buttonHistoric = t('hide_history')
    return (
      <div className={supportRight ? "table_80": "table_80"}>
        <div className="table_buttons">
          {supportRight ? <button className="only_support" onClick={() => {this.insertRow()}}>{t("addline")}</button> : null }
          <button className={this.state.historic ? "button_active" : null} onClick={() => {this.setState({ historic: !this.state.historic, }); this.componentDidMount(!this.state.historic)}}>{buttonHistoric}</button>
          {userRight ? <button className="only_support" onClick={() => {this.setState({ showHideEdit: true, })}}>{t("box_edit_path")}</button> : null }
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_box_table", }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div>
        </div>
        {this.state.tablePageSize > 0 ?
        <div style={{"font-size": this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {this.state.tablePageSize}
          pageSizeOptions = {[25, 50, 100]}
          useFlexLayout = {true}
          expanded={this.state.expanded}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : this.getStatusBackColor(rowInfo.row.status),
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.id < 0 ? 'red' : this.getStatusColor(rowInfo.row.status),
                  fontSize: rowInfo.row.status > 4 ? "0.9em" : null
                }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick:  (e) => {if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText)},
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        : null}
        {this.state.showHideEdit && <Edit paths = {this.state.pathList} closeDiv={this.closeEdit} reload={this.reload} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Boxes;
