import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
import Translation from '../user/Translation'
import UserProfile from '../user/UserProfile'
import {setTableFontSize} from './api/imports.js'
import GetJsonFormPHP, {PostJsonFormPHP} from "./api/GetJsonFormPHP"
//import {setTableFontSize} from './api/imports.js'
import Select from 'react-select'
import Info from "./api/Info"
import './styles/react-table.css'
//import imgFalse from './img/false.png'
//import imgFalse2 from './img/false2.png'
//import imgChange from './img/install.png'
//import imgTrue from './img/true.gif'
//import imgSend from './img/send.png'
//import imgPwd from './img/password.png'

function t(field) { return Translation.get(field) }

class Users extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_users.php",
      saveurl: "/users/set_users.php",
      sendurl: "/users/request_password.php",
      setTableUrl: "/users/change_tablesize.php",
      orgData: [], data: [], empty: null, selected: null,// Table data
      customers: null, userlevels: null, languages: null,  // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      confirmVisible1: -1, insert: false, // Send Usermail-Box
      showHelp: false, showHelpId: null, // Info popup
      filterCustomer: 0, filterName: "", customerSelect: null, // Filter Options
      fieldWidths: null, manageFields: false, tablePageSize: 0, // Show table fields
      fontSize: setTableFontSize(),
    }
    this.sendUserData = this.sendUserData.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      this.setState({
        orgData: result.data,
        data: result.data,
        customers: result.customers,
        userlevels: result.userlevels,
        languages: result.languages,
        customerSelect: result.customerselect,
        fieldWidths: result.fieldwidth,
        tablePageSize: pageSize,
      })
      if (this.state.filterCustomer > 0 || this.state.filterName !== "") this.setfilter()
    })
  }
  setfilter(value) {
    //console.log(value)
    let filter1 = this.state.filterCustomer
    let filter2 = this.state.filterName
    if (value && value.name === "filter_customers") filter1 = value.value
    if (value && value.name === "filter_name") filter2 = value.value
    let  a = []
    if (filter1 > 0) {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.customer_id === parseInt(filter1) || t.id === -1)  a = [...a, t] })
    }
    else a = this.state.orgData
    let  a1 = []
    if (filter2 !== "") {
      // eslint-disable-next-line array-callback-return
      a.map((t,k) => {
        if (t.login.toLowerCase().includes(filter2.toLowerCase()) || (t.email !== null && t.email.toLowerCase().includes(filter2.toLowerCase())) || (t.name !== null && t.name.toLowerCase().includes(filter2.toLowerCase())) || t.org_id === -1) a1 = [...a1, t]
      })
    }
    else a1 = a
    this.setState({
      data: a1,
      filterCustomer: filter1,
      filterName: filter2,
      selected: null,
    })
  }
  radioboxCell(initialValue, id, row, options, edit = true) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    //console.log(id, row.login, initialValue)
    initialValue = parseInt(initialValue)
    if (this.state.selected === row._index && edit) {
      return (
        <div>
          <label><input type="radio" name={id} value="1" className="checkbox" checked={initialValue === 1} onChange={inputChange} />{options[1]}</label><br />
          <label><input type="radio" name={id} value="0" className="checkbox" checked={initialValue === 0} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === 1) return <div align="center" title={t("enabled")} className="icon_s green">✔</div>
      else {
        if (initialValue === 0) return <div align="center" title={t("disabled")} className="icon_s darkred">⛌</div>
        if (initialValue === 12) return <div align="center" title={t("user_changecode")} className="icon_s blue">🢚</div>
        if (initialValue === -1) return <div align="center" title={t("deleted")} className="icon_s">⛔</div>
        else return null
      }
    }
  }
  sendUserData(confirm, insert = null) {
    //const id = this.state.confirmVisible1
    if (confirm === true && this.state.choise) {
      let row = this.state.data[this.state.confirmVisible1]
      console.log(row)
      row.password = insert
      if (row) PostJsonFormPHP(this.state.sendurl, row).then((result) => {
        //this.reload()
      });
    }
    this.setState({confirmVisible1: -1, insert: true})
  }
  addSelectUserLevel(header, accessor, options, cellwidth = 0, show = true, align = null, cellColor = null, addempty = false, edit = true, filterable = false, value = false, mouseoverOptions = null, ratio = false, editOnNew = false, excludeId = -1) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let result = {
      Header: t(header),
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row}) => { return this.selectUserLevel(id , row, initialValue, options, addempty, edit || (editOnNew && row.id <= 0), value ? initialValue : null, mouseoverOptions, ratio, excludeId) },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellwidth > 0) result.width = cellwidth*em
    return result
  }
  selectUserLevel(id, row, position, options, addempty, edit, initialvalue, mouseoverOptions, ratio, excludeId) {
    let color1 = null
    const inputChange = value => { this.handleInputChange(index, id, value); }
    const _handleKeyDown = e => { if (e.key === 'Enter') this.handleSubmit(row) }
    const index = row._index
    const default_value = {value: '', label: t('use_default'), color: '#BBBBBB'}
    if (Array.isArray(options) && options[0].value) {
      //console.log("*", position)
      if (position === -1) color1 = default_value.color;
      Object.entries(options).map((t,k) => { if (t[1].value === position) color1 = t[1].color; return null })
    }
    if (this.state.selected === index && edit === true && row.id !== parseInt(excludeId)) {
      return (
        <select name={id} value={position} onChange={inputChange} onKeyDown={_handleKeyDown}  style={{ color: color1, }}>
          {addempty ? <option key="0" value={default_value.value} style={{color: default_value.color}}>{default_value.label}</option> : ""}
          { Array.isArray(options) && options[0].value ? Object.entries(options).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, } : null }>{t[1].label}</option>)
            : Object.entries(options).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
        </select>
      )
    }
    else {
      let output, mouseover = ""
      if (Array.isArray(options) && options[0].value) Object.entries(options).map((t,k) => { if (t[1].value === position) output = t[1].label; return null })
      else {
        output = options[position]
        if (mouseoverOptions) mouseover = mouseoverOptions[position]
        else mouseover = output
      }
      if (ratio) {
        if (position === 0) return <div align="center" title={mouseover} className="icon_s">⛔</div>
        else return <div align="center" title={mouseover} className="icon_s green">✔</div>
      }
      else return (<span title={mouseover}>{initialvalue !== null ? initialvalue : output}</span>)
    }
  }
  createColumns() {
    const suRight = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkRight("support")
    const custUser = UserProfile.checkRight("custuser")
    let traderRight = UserProfile.checkRight("addcust")
    const fontSize = this.state.fontSize
    const w = this.state.fieldWidths
    //console.log(w)
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*fontSize
    const columns = [
      this.addTextCell('§ID', 'id', 0, (w && w['id'] ? w['id'] : 4)*fontSize, suRight, "", null, null, null, false),
      this.addTextCell('login_username', 'login', 0,  (w && w['login'] ? w['login'] : 0)*fontSize, true, false, false, false, null, false, false, 0, null, true, true),
      this.addSelectCell("customer", "customer_id", this.state.customerSelect,  (w && w['customer_id'] ? w['customer_id'] : 15)*fontSize, traderRight, null, null, false, false, false, false, null, false, true),
      this.addTextCell('name', 'name', 0,  (w && w['name'] ? w['name'] : 10)*fontSize),
      this.addSelectUserLevel("userlevel", "userlevel_id", this.state.userlevels,  (w && w['userlevel_id'] ? w['userlevel_id'] : 12)*fontSize, true, null, null, false, true, false, false, null, false, false, UserProfile.getId()),
      this.addTextCell('email', 'email', 0,  (w && w['email'] ? w['email'] : 15)*fontSize),
      { accessor: 'password', show: false,},
      this.addSelectCell("language", "language_id", this.state.languages,  (w && w['language_id'] ? w['language_id'] : 6.5)*fontSize),
      this.addTextCell('fontsize', 'fontsize', 2,  (w && w['fontsize'] ? w['fontsize'] : 4)*fontSize, true),
      this.addTextCell('telephone', 'telephone', 0,  (w && w['telephone'] ? w['telephone'] : 12)*fontSize, true),
      this.addTextCell('user_messages', 'messages', 0,  (w && w['messages'] ? w['messages'] : 10)*fontSize, true),
      this.addTextCell('userrights', 'rights', 0,  (w && w['rights'] ? w['rights'] : 29)*fontSize, suRight, "", null, null, null, false),
      { accessor: 'enabled',
        Header: t('enabled'),
        maxWidth:  (w && w['enabled'] ? w['enabled'] : 5)*em,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const userID = parseInt(UserProfile.getId())
          if (row.id !== userID) return this.radioboxCell(initialValue, id , row, [t("disabled"), t("enabled")])
          else return <div align="center" title={t("enabled")} className="icon_s green">✔</div>
        },
      },
      //this.addSelectCell("user_changecode", "changecode", ["ok", "x"], [t("yes"), t("no")], (w && w['changecode'] ? w['changecode'] : 3.5)*fontSize, traderRight, null, this.getColor('readOnly'), false, false),
      { accessor: 'changecode',
        show: supportRight,
        Header: t('user_changecode'),
        maxWidth:  (w && w['changecode'] ? w['changecode'] : 5)*em,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return this.radioboxCell(initialValue, id , row, [null, null], false)
        },
      },
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 11*em,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const userID = UserProfile.getId()
          return (
            <div>
              {this.edit(row, false, suRight && row.id !== 4, (custUser || supportRight) && row.id !== 4)}
              { parseInt(userID) === row.id ? <span title={t("user_change_password")} onClick={() => { this.setState({confirmVisible1: row._index, insert: t("user_new_password"), title: t("user_change_password") + " " + row.login, choise: true, })}} className="icon_link_t i_pwd">&nbsp; &nbsp;&nbsp;</span> : null }
              {(custUser || supportRight) && row.id !== 4  && row.id !== -1 ? <span title={t("send_login")} onClick={() => { this.setState({confirmVisible1: row._index, title: t("user_send_login"), choise: true, insert: "", })}} className="icon_link_t i_key">&nbsp; &nbsp;&nbsp;</span> : null}
            </div>
          )
        },
      }
    ]
    return columns
  }

  render() {
    //const userColor = ['black', '#DD0000', 'DarkOrange', 'SeaGreen', 'LightSeaGreen', 'DarkBlue', 'SteelBlue']
    const backColors = ['black', '#FFDDDD', '#FFEEEE', 'LightYellow', 'Linen', 'LightSteelBlue', '#DAF4FE']
    const columns = this.createColumns()
    const supportRight = UserProfile.checkRight("support")
    let traderRight = UserProfile.checkRight("trader")
    const userRight = UserProfile.checkRight("custuser")
    const filterChange  = value => { this.setfilter(value.target) }
    const selectChange  = value => { console.log(value); this.setfilter(value) }
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=user&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    //var ua = navigator.userAgent
    return (
      <div className={supportRight ? "": "table_100"}>
        <div className="table_buttons">
          {userRight ? <button onClick={() => {this.insertRow()}}>{t("addline")}</button> : null}
          { traderRight && this.state.orgData.length > 2 ? <span>
          {t('filter_customer')}:&nbsp;
          <Select
            options={this.state.customerSelect}
            styles={colourStyles}
            isSearchable={true}
            isClearable={true}
            className="chartselect"
            onChange={(value) => {selectChange({name: "filter_customers", value: value !== null ? value.value : 0})}}
            placeholder={t('select')}
          />
          {t('filter_name')}:&nbsp;
            <input type="text" name="filter_name" value={this.state.filterName} onChange={filterChange} onKeyDown={filterChange}/> &nbsp;
          </span> : null }
          <div className="right_side">
            <span title="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_users_table", }) } } className="icon_link i_info">&nbsp; &nbsp;</span>
          </div>
        </div>
        {this.state.tablePageSize > 0 ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          //filterable = {filter}
          defaultPageSize = {this.state.tablePageSize}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && userRight && rowInfo.row.id !== 4) { this.setState({ selected: rowInfo.index, }) } },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : backColors[rowInfo.row.userlevel_id],
                color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.enabled === -1 ? "red" : 'black'
              }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick:  (e) => { if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText) },
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        : null }
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        <AlertDialog  openDialog = {this.state.confirmVisible1 > -1} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} insert = {this.state.insert} callback = {this.sendUserData}/>
      </div>
    )
  }
  //         Platform:  {ua} | {UserProfile.isSafari()} {window.sessionStorage.getItem("browser")}
}
export default Users;
