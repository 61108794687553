import TableFunctions from '../datatables/api/TableFunctions.js'
import ReactTable from "react-table-6"
import Translation from '../user/Translation.js'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP.js";
import UserProfile from '../user/UserProfile.js'
import '../datatables/styles/react-table.css'
//import imgEdit from '../datatables/img/editdetail.png'

function t(field) { return Translation.get(field) }

class Downloads extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_downloads.php",
      fileUrl: "/users/upload_service_pdf.php",
      //saveCommentUrl: "/users/set_comment.php",
      data: [], dataSupport: [], empty: null, selected: -1,
      //toDelete: "",
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      if (result.data === null) result.data = [{id : -1}]
      this.setState({
        contact: result.contact,
        data: result.data,
        dataSupport: result.data1,
      })
    })
  }
  addDownloadCell(header, accessor, isInt = 0, cellWidth = 0, show = true, addText = "", align = null, cellColor = null, textColor= null, edit = true, filterable = false, decimals = -1, mouseoverText = null, need = false, editOnNew = false) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let headertext = t(header)
    if (header.substring(0, 1) === "§") headertext = header.substring(1, 1000)
    let result = {
      Header: headertext,
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row,}) => {
        let link = row.path + row.filename
        let text = ""
        //console.log(row)
        switch (row.type) {
          case "robot-docu":
            text = initialValue
            //text = t("customer_short_docu")
            break
          default: text = initialValue
        }
        //text = text + " (" + row.language.toUpperCase() + ")"
        if (typeof initialValue === 'undefined') initialValue = ""
        return (<a href={"https://connect.hetwin.at/public/" + link} target="_blank" rel="noreferrer">{text}</a>)
        //return (<span title={row.filename}><span dangerouslySetInnerHTML={{__html: link}}></span></span>)
        //return this.textareaCell(initialValue, id , row)
      },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, color: textColor, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor } }),
      filterable: filterable,
    }
    if (cellWidth > 0) result.width = cellWidth*em
    return result
  }

  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    //const supportRight = UserProfile.checkRight("support")
    //const userRight = UserProfile.checkRight("custuser")
    //const fontSize = this.state.fontSize
    return [
      { accessor: 'id', show: false,},
      { accessor: 'type', show: false,},
      { accessor: 'path', show: false,},
      { accessor: 'filename', show: false,},
      //this.addTextCell('downloads_filename', 'filename', 0, 0, true, "", null, null, null, false),
      this.addDownloadCell('downloads_name', 'download_name', 0, 0, true, "", "left", null, null, false),
      { accessor: 'language',
        Header: t('language'),
        show: true,
        width: 5.8*em,
      },
      /*{ accessor: 'edit',
        Header: t('table_edit'),
        width: 10.5*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return (
            <div>
              <span><img src={imgEdit} alt={t("*edit")} title={t("*edit")} onClick={() => { this.editEntry(row) }}/></span>
            </div>
          )
        },
      }*/
    ]
  }
  createColumnsSupport() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    //const supportRight = UserProfile.checkRight("support")
    //const userRight = UserProfile.checkRight("custuser")
    //const fontSize = this.state.fontSize
    return [
      { accessor: 'name', Header: "Filename", show: true,},
      { accessor: 'size',
        Header: "Filesize",
        show: true,
        width: 8*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: "right" } }),
      },
      { accessor: 'date',
        Header: "Upload Date",
        show: true,
        width: 15*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: "center" } }),
      },
      //this.addTextCell('downloads_filename', 'filename', 0, 0, true, "", null, null, null, false),
      { accessor: 'download',
        Header: "Download",
        show: true,
        width: 6.5*em,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return (<a href={"https://connect.hetwin.at/public/uploads/remote_transfer/" + row.name} target="_blank" rel="noreferrer">Download</a>)
        },
        getProps: (state, rowInfo) => ({ style: { textAlign: "center" } }),
      },
      { accessor: 'delete',
        Header: "Delete",
        show: true,
        width: 4.5*em,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const deletFile = value => { this.deletFile(row.name) }
          return <div align="center" title={t("delete")} onClick={deletFile} className="icon_link_s i_trash">&nbsp; &nbsp;&nbsp;</div>
        },
        getProps: (state, rowInfo) => ({ style: { textAlign: "center" } }),
      },
    ]
  }
  deletFile(fileName) {
    //console.log(fileName)
    GetJsonFormPHP(this.state.fileUrl + "?del=remote&file=" + fileName).then((result) => {
      this.componentDidMount()
    })
  }

  render() {
    const columns = this.createColumns()
    const columnsSupport = this.createColumnsSupport()
    //console.log(this.state.comment_type, this.state.editable)
    const supportRight = UserProfile.checkRight("support")
    //const adminRight = UserProfile.checkRight("admin") || suRight


    return (
      <div className="table_60">
        {UserProfile.checkSupport() ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
          {this.state.data ?
          <ReactTable
            id="react-table"
            data={this.state.data}
            columns={columns}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            showPageJump= {true}
            defaultPageSize = {25}
            pageSizeOptions = {[10, 25, 50, 100]}
            useFlexLayout = {true}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  style: { fontSize: null }
              }
            }}
          /> : null }  <br />
          {supportRight ? <div>
            <div className="uploadform"><form action={process.env.REACT_APP_API_URL +  "/" + this.state.fileUrl} method="post" enctype="multipart/form-data" target="_blank">
              Select File to upload: &nbsp;
              <input type="file" name="fileToUpload" id="fileToUpload" /> &nbsp; &nbsp;
              <input type="hidden" name="type" value="remote_transfer" />
              <input type="submit" value={t("submit")} name="submit" />
            </form></div>
          </div> : null}<br/>
          {this.state.dataSupport ?
          <ReactTable
            id="react-table"
            data={this.state.dataSupport}
            columns={columnsSupport}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            showPageJump= {true}
            defaultPageSize = {25}
            pageSizeOptions = {[10, 25, 50, 100]}
            useFlexLayout = {true}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  style: { fontSize: null }
              }
            }}
          /> : null }  <br />
        </div> : null }
      </div>
    )
  }
}

export default Downloads;