import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import AlertDialog from "../user/Dialog.js";
import Draggable from "react-draggable"
import Translation from '../user/Translation.js';
import UserProfile from '../user/UserProfile.js';
import GetJsonFormPHP from "./api/GetJsonFormPHP.js";
//import {setTableFontSize} from './api/imports.js';
import TextField from '@mui/material/TextField';
import Info from "./api/Info.js";
import Select from 'react-select'
import './styles/react-table.css';
//import './styles/tablestyles.css';
import { format } from 'date-fns';
//import imgDetail from './img/editdetail.png'
//import imgFalse from './img/false.png';
//import imgTrue from './img/true.gif';
//import { Checkbox } from '@material-ui/core';

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      row: null, data: null, empty: null, selected: null, // Table data
    }
    this.handleClose = this.handleClose.bind(this)
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.row.id) {
      return {
        index: props.index,
        //id: props.row.id,
        row: props.row,
        //data: drives,
        selected: null,
      }
    }
    return null
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+50*em)+'px'
    e1.style.top =(window.scrollY+20*em)+'px';
    //e1.style.left = (this.state.position[0]-28*em)+'px';
    //e1.style.top =(this.state.position[1]-2*em)+'px';
  }

  render() {
    //console.log("Drives", this.state.maxDrives)
    //console.log(this.state.row)
    const traderRight = UserProfile.checkRight("trader")
    if (this.state.id !== 0) {
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        e1.style.left = (window.scrollX+40*em)+'px'
        e1.style.top =(window.scrollY+20*em)+'px';
        //e1.style.left = (this.state.position[0]-28*em)+'px';
        //e1.style.top = (this.state.position[1]-2*em)+'px';
      }
      return (
        <Draggable handle=".handle">
        <div className="edittable edittable_large" id="edit_window">
          <div className="header">
            <div className="header_inline handle">{this.state.row.type}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <table className="errorlist" cellspacing="0" cellpadding="0">
            <tbody>
              <tr><td className="descr">{t("error_descr1")}</td><td className="error"><span dangerouslySetInnerHTML={{__html: this.state.row.error}}></span></td></tr>
              <tr><td className="descr">{t("error_descr2")}</td><td className="error"><span dangerouslySetInnerHTML={{__html: this.state.row.code}}></span></td></tr>
              <tr><td className="descr">{t("error_solution1")}</td><td className="solution"><span dangerouslySetInnerHTML={{__html: this.state.row.solution}}></span></td></tr>
              {traderRight ? <tr><td className="descr">{t("error_solution2")}</td><td className="solution"><span dangerouslySetInnerHTML={{__html: this.state.row.solution_service}}></span></td></tr> : null}
              {traderRight ? <tr><td className="descr">PDF</td><td className="solution"><a href={"https://connect.hetwin.at/public/uploads/service_pdf/" + UserProfile.getUserLanguage() + "/" + this.state.row.service_pdf} rel="noreferrer" target="_blank">{this.state.row.service_pdf}</a></td></tr> : null}
            </tbody>
          </table>
          <div style={{"font-size": this.state.fontSize+"em"}}>
          </div>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}




class ErrorList extends TableFunctions {
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-7)
    let warning = true
    if (UserProfile.checkRight("addcust"))  warning = false
    this.state = {
      geturl: "/robots/get_errors.php",
      saveurl: "/robots/set_errors.php",
      orgData: [], data: [], empty: null, orgDataHits: [], errorHits: [], selected: null, // Table data
      customers: null, levelError: true, levelWarning: warning, levelInfo: false, // Select input options
      startDate: format(startDay, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      showHelp: false, showHelpId: null, // Info popup
      showPosition: [], showHideError: false, selectedRow: null, advancedIndex: null, // Advanced insert window
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      robotFilter: 0, customerFilter: 0, errorFilter: "", customerSelect: null, robotSelect: null,  // Filter Options
      fontSize: 0.8, //setTableFontSize(),
      customerID: 0,
    }
    this.closeDiv = this.closeDiv.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount(error = this.state.levelError, warning = this.state.levelWarning, info = this.state.levelInfo) {
    GetJsonFormPHP(this.state.geturl + "?show=" + error + "-" + warning + "-" + info + "&start=" + this.state.startDate + "&customer_id=" + this.state.customerID).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        orgData: result.data,
        data: result.data,
        customers: result.customers,
        robotSelect: result.robotselect,
        customerSelect: result.customerselect,
        typeSelect: result.typeselect,
        errorHits: result.err,
        orgDataHits: result.err,
      })
    })
  }
  static getDerivedStateFromProps(props, state) {
    //console.log(props, state.customerID)
    if (props.customerID) {
      if (props.customerID !== state.customerID) {
        //console.log("set props")
        let startDay = new Date()
        startDay.setDate(startDay.getDate()-3)
        return {
          customerID: props.customerID,
          levelError: true, levelWarning: false, startDate: format(startDay, 'yyyy-MM-dd'),
        }
      }
      else return null
    }
    return {
      customerID: 0,
    }
  }

  getData(error, warning, info, start, end) {
    //console.log(error, warning, info, start, end)
    GetJsonFormPHP(this.state.geturl + "?show=" + error + "-" + warning + "-" + info + "&start=" + start + "&end=" + end + "&customer_id=" + this.state.customerID).then((result) => {
      this.setState({
        orgData: result.data,
        data: result.data,
        customers: result.customers,
        robotSelect: result.robotselect,
        customerSelect: result.customerselect,
        typeSelect: result.typeselect,
        errorHits: result.err,
      })
    })
  }
  /*radioboxCell(initialValue, id, row, options) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
        <label><input type="radio" name={id} value="1" className="checkbox" checked={row.enabled === "1"} onChange={inputChange} />{options[1]}</label><br />
        <label><input type="radio" name={id} value="0" className="checkbox" checked={row.enabled === "0"} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === "1") return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
    }
  }*/
  setfilter(value) {
    let filter1 = this.state.customerFilter
    let filter2 = this.state.robotFilter
    let filter3 = this.state.errorFilter
    if (value.name === "filter_customers") filter1 = value.value
    if (value.name === "filter_robots") filter2 = value.value
    if (value.target && value.target.name === "filter_errorcode") filter3 = value.target.value
    let  res = this.state.orgData
    let a =[]
    if (filter1) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.customer_id === parseInt(filter1) || t.id === -1 || t.trader_id === parseInt(filter1) || t.importer_id === parseInt(filter1))  a = [...a, t] })
      res = a
    }
    a = []
    if (filter2 > 0) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.robottype === parseInt(filter2) || t.id === -1)  a = [...a, t] })
      res = a
    }
    a = []
    if (filter3 !== "") {
      const filter = filter3.toLowerCase()
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.type.toLowerCase().includes(filter) || t.org_id === -1) a = [...a, t] })
      res = a
    }
    this.setState({
      data: res,
      customerFilter: filter1,
      robotFilter: filter2,
      errorFilter: filter3,
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    //const suRight = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    //const userRight = UserProfile.checkRight("custuser")
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'color', show: false,},
      { accessor: 'warn', show: false,},
      { accessor: 'robottype', show: false,},
      this.addSelectCell("customer", "customer_id", this.state.customers, 10*this.state.fontSize, traderRight),
      //this.addSelectCell("robot_name", "robottype_id", this.state.robottypes, 0, true, null, null, false, suRight),
      //this.addTextCell('robot_id_name', 'id_name', 0, 12, true, "", null, this.getColor('cellAdmin')),
      this.addTextCell('robotname', 'robot', 0, 10*this.state.fontSize, true, "", null, null, 'row'),
      //this.addTextCell('ip_wlan', 'ip_wlan', 2, 6.5, suRight, "", 'right', this.getColor('cellAdmin')),
      this.addTextCell('errors_time', 'timestamp', 0, 12*this.state.fontSize, true, "", null, null, 'row'),
      this.addTextCell('errors_code', 'type', 0, 6.5*this.state.fontSize, true, "", null, null, 'row'),
      this.addTextCell('errors_descr', 'message', 0, 0, true, "", null, null, 'row'),
      this.addTextCell('§Error System', 'error', 0, 0, supportRight, "", null, null, 'row'),
      //this.addTextCell('§Beschreibung Support', 'code', 0, 0, supportRight, "", null, null, 'row'),
      { accessor: 'code', show: false,},
      //this.addTextCell('§Lösung', 'solution', 0, 0, true, "", null, null, 'row'),
      { accessor: 'solution', show: false,},
      { accessor: 'solution_service', show: false,},
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideError: true,
              showPosition: [value.clientX, value.clientY],
            })
            //console.log(this.state.showPosition, value.clientX, value.clientY)
          }
          return <span title={t("details")} onClick={getDetails} className="icon_link_t i_detail">&nbsp; &nbsp;&nbsp;</span>
        },
      }
    ]
    return columns
  }
  createColumnsHits() {
    //const supportRight = UserProfile.checkRight("support")
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'color', show: false,},
      //this.addSelectCell("customer", "customer_id", this.state.customers, 10, true),
      //this.addTextCell('errors_descr', 'message', 0, 0, true, "", null, null, 'row'),
      //this.addTextCell('errors_code', 'type', 0, 6.5, true, "", null, null, 'row'),
        //this.addTextCell('errors_descr', 'message', 0, 0, true, "", null, null, 'row'),
        //this.addTextCell('§' + t('errors_descr') + " Support", 'code', 0, 0, true, "", null, null, 'row'),
      //this.addTextCell('§so oft aufgetreten', 'i', 0, 0, true, "", null, null, 'row'),
    ]
    return columns
  }
  closeDiv() {
    this.setState({
      showHideError: false,
    })
  }

  render() {
    const columns = this.createColumns()
    const columnsHits = this.createColumnsHits()
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const selectChange  = value => { this.setfilter(value) }
    const inputChange = value => {
      switch(value.target.name) {
        case "level_error":
          if (this.state.levelWarning || this.state.levelInfo) {
            this.setState({ levelError: !this.state.levelError })
            this.componentDidMount(!this.state.levelError, this.state.levelWarning, this.state.levelInfo)
          }
          break
        case "level_warning":
          if (this.state.levelError || this.state.levelInfo) {
            this.setState({ levelWarning: !this.state.levelWarning })
            this.componentDidMount(this.state.levelError, !this.state.levelWarning, this.state.levelInfo)
          }
          break
        case "level_info":
          if (this.state.levelError || this.state.levelWarning) {
            this.setState({ levelInfo: !this.state.levelInfo })
            this.componentDidMount(this.state.levelError, this.state.levelWarning, !this.state.levelInfo)
          }
          break
        case "start_date":
          this.setState({ startDate: value.target.value })
          break
        case "end_date":
          this.setState({ endDate: value.target.value })
          break
        default: break
      }
    }
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    //console.log(this.state.errorHits)
    return (
      <div className={supportRight ? "": "table_100"}>
        <div className="table_buttons">
          { traderRight && this.state.orgData.length > 2 ? <div className="erors_inline">
            <br />
            <Select
              options={this.state.customerSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_customers", value: value !== null ? value.value : 0})}}
              placeholder={t('filter_customer')}
            />
          </div> : null }
          <div className="erors_inline">
            <br />
            <Select
              options={this.state.robotSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_robots", value: value !== null ? value.value : 0})}}
              placeholder={t('filter_robots')}
            />
          </div>
          <div className="erors_inline">
            {t('filter_type')}:&nbsp;<br />
            <input type="text" name="filter_errorcode" value={this.state.filterLan} onChange={selectChange} onKeyDown={selectChange} className="chartselect"/>
          </div>
          <div className="erors_inline errors">
            <span className="error"><input type="checkbox" name="level_error" value="1" className="checkbox" checked={this.state.levelError} onChange={inputChange} /> {t("errors_status_error")}</span>
            <span className="warning"><input type="checkbox" name="level_warning" value="1" className="checkbox" checked={this.state.levelWarning} onChange={inputChange} /> {t("errors_status_warning")}</span><br></br>
            <span className="info"><input type="checkbox" name="level_info" value="1" className="checkbox" checked={this.statelevelInfo} onChange={inputChange} /> {t("errors_status_info")}</span>
          </div>
          <div className="erors_inline datefield_small"><div>{t("start_date")}:</div><div><TextField name="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={inputChange} className="datefield_small"/></div></div>
          <div className="erors_inline datefield_small"><div>{t("end_date")}:</div><div><TextField name="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={inputChange} className="datefield_small"/></div></div>
          <div className="erors_inline" align="center"><button onClick={() => {this.getData(this.state.levelError, this.state.levelWarning, this.state.levelInfo, this.state.startDate, this.state.endDate)}} className="button">{t("show")}</button></div>
          <div className="right_side">
            <span title="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_errors_table", }) } } className="icon_link i_info">&nbsp; &nbsp;</span>
          </div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {100}
          pageSizeOptions = {[25, 50, 100, 200]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => {
                //if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) }
              },
              style: {
                background: rowInfo && rowInfo.row.warn === 1 ? '#FFDDDD' : rowInfo.row.warn === 2 ? '#FFF5E8' : rowInfo.row.warn === 0 ? '#EEFFFF' : 'white',
                color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : 'black'
              }
            }
          }}
        />
        {supportRight && this.state.errorHits ?
        <ReactTable
          data={this.state.errorHits}
          columns={columnsHits}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {50}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => {
                //if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) }
              },
              style: {
                background: 'white',
                color: 'black'
              }
            }
          }}
        /> : null }
        </div>
        {this.state.showHideError && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} position = {this.state.showPosition} closeDiv={this.closeDiv} reload={this.reload} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default ErrorList;
